.loading-spinner {
    border: 8px solid #1a1a1a;
    border-top: 8px solid #FFA500;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Sentrer spinneren */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  body {
    margin: 0;
  }
  